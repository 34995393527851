<template>
    <li>
      <div class="tree-item">
      <span v-if="isFolder">
        <font-awesome-icon class='tree-item-icon' icon="folder-open"/>
      </span>
        <span v-else>
          <font-awesome-icon class="tree-item-icon has-text-danger" icon="home" v-if="isHome"/>
          <font-awesome-icon class='tree-item-icon' icon="file-alt" v-if="!isHome"/>
      </span>
        <span>&nbsp;&nbsp;{{ item.name }}</span>
      </div>
      <div v-if="isFolder">
        <ul>
          <tree-item-r-o
            class="item"
            v-for="(child, index) in item.children"
            :key="index"
            :item="child"
            :home="home"
            :libraries="libraries"
          ></tree-item-r-o>
        </ul>
        <ul class="dragArea" v-if="isPublicLib">
          <li v-for="(lib, index) in libraries" :key="index">
            <font-awesome-icon class='tree-item-icon' icon="file-alt"/>&nbsp;{{lib}}
          </li>
        </ul>
      </div>
    </li>
</template>

<script>
export default {
  name: 'TreeItemRO',
  props: ['item', 'activeItem', 'siblings', 'home', 'libraries'],
  data () {
    return {
    }
  },
  computed: {
    isPublicLib () {
      return this.item.isPublicLib === true
    },
    isFolder () {
      if (this.item.children) {
        return true
      }
      return false
    },
    isHome () {
      let parent = '/'

      if (this.item.parent && this.item.parent === '/') {
        parent = this.item.parent + this.item.name
      } else if (this.item.parent) {
        parent = this.item.parent + '/' + this.item.name
      }
      return (parent === this.home)
    }
  },
  methods: {

  }
}

</script>

<style scoped>
  .item {
    cursor: pointer;
  }

  ul {
    padding-left: 1em;
    line-height: 1.5em;
    list-style-type: dot;
  }

  .tree-item {
    padding: 2px;
  }

  .tree-item:hover {
    background: lightgray;
    color: white;
  }

  .tree-item.active {
    background: #55acee;
    color: white;
  }

  .tree-item-icon {
    color: grey;
  }

  .active .tree-item-icon {
    color: white;
  }

  .is-right {
    float: right;
  }

  .item-settings {
    padding-right: 5px;
  }

  a.dropdown-item {
    font-size: 0.9em;
  }

  .edit-div {
    display: inline-block;
    width: 70%;
    margin-left: 10px;
  }

  input {
    background: #e8e8e8;
  }

  .dragArea {
    /*min-height: 50px;*/
    /*outline: 1px dashed;*/
  }

  .sortable-ghost {
    background: skyblue;
    color: white;
  }

</style>
