<template>
  <SideBar @toggle="$emit('toggle')" class="is-large" ref="sideBar">
    <div>
      <div><PageTitle :title="title"/></div>
      <div class="doodle-display-box">
        <Spinner v-show="showInProgressSection && !isMounted" message="JDoodle is loading your projects, please wait..."/>
        <div class="column is-8 is-offset-2" v-show="!isMounted && doodleForm.errorMessage">
          <DoodleMessage :message="doodleForm.errorMessage" message-class="has-text-danger"/>
        </div>
        <div>
          <div>
            <div v-show="showMainSection">
              <div class="columns is-mobile file-div is-multiline">
                <div class="column is-4 file-list is-paddingless">
                  <div class="file-list-items" v-for="project in projects" :key="project.id">
                    <div class="file-list-item" @click="loadFile(project)" v-bind:class="{'is-active': isSelected(project)}">
                      <font-awesome-icon icon="file-alt" class="has-text-grey-dark"/>&nbsp;&nbsp;{{project.name}}
                    </div>
                  </div>
                </div>

                <div class="column is-8 text-center" v-show="showInProgressSection">
                  <Spinner message="JDoodle is loading your file, please wait..."/>
                </div>
                <div class="column is-8 text-center" v-show="doodleForm.errorMessage">
                  <DoodleMessage :message="doodleForm.errorMessage" message-class="has-text-danger"/>
                </div>
                <div class="column is-8 code-editor-div"  v-show="!showInProgressSection && !doodleForm.errorMessage" v-if="!isMultiFileLang">
                  <div id="myFilesEditor" class="code-editor"></div>
                </div>
                <div class="column is-8 code-editor-div"  v-show="!showInProgressSection && !doodleForm.errorMessage" v-if="isMultiFileLang">
                  <ul>
                    <TreeItemRO
                      class="item"
                      :item="project.script.treeData" :libraries="project.libraries"
                      :home="project.script.home" v-if="project"></TreeItemRO>
                  </ul>
                </div>
                <div class="column is-12 is-marginless is-paddingless" v-show="showFileDeleted">
                  <DoodleMessage message="File deleted successfully" message-class="has-text-success"/>
                </div>
                <div class="column is-6">
                  <div v-if="showShared">
                    <div class="has-text-weight-semibold has-text-centered has-text-white is-size-7 copied-to-clipboard" v-show="copiedToClip">{{showCopiedMessageText}}</div>
                    <div class="share-div">
                      Share URL - <span class="share-box has-text-weight-bold">{{project.url}}</span>
                      <a @click="copyToClipboard(project.url)"><font-awesome-icon icon="copy" class="has-text-danger"/></a>
                    </div>
                    <div class="share-div" v-if="language!=='html' && !isMultiFileLang">
                      Embed URL - <span class="share-box has-text-weight-bold">{{project.embedUrl}}</span>
                      <a @click="copyToClipboard(project.embedUrl)"><font-awesome-icon icon="copy" class="has-text-danger"/></a>
                    </div>
                  </div>
                </div>
                <div class="column is-6 buttons has-text-centered">
                  <div v-show="!showDeleteConfirm">
                    <button class="button is-info" v-show="project" @click="openProject">Open</button>
                    <button class="button is-danger" v-show="project" @click="showDeleteConfirm=true">Remove</button>
                    <button class="button" @click="$refs.sideBar.emitToggle()">Close</button>
                  </div>
                  <div class="ide-menu-box is-marginless" v-bind:class="{'is-active': showDeleteConfirm}">
                    <div class="box is-marginless">
                      <div class="has-text-centered confirm-text has-text-weight-semibold">Are you sure, do you want to delete this project?</div>
                      <div class="columns is-mobile">
                        <div class="column is-6 has-text-right">
                          <button class="button is-small is-rounded has-text-weight-bold is-info" @click="deleteProject">Yes</button>
                        </div>
                        <div class="column is-6 has-text-left">
                          <button class="button is-small is-rounded has-text-weight-semibold " @click="showDeleteConfirm=false">No</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SideBar>
</template>

<script>
import formMixin from '../assets/javascript/form-mixin'
import SideBar from './Utils/SideBar'
import PageTitle from './PageTitle'
import DoodleMessage from './Utils/DoodleMessage'
import Spinner from './Utils/Spinner'
import { ACE_UTIL } from '../assets/javascript/ace-util'
import { IDE_CONST } from '../assets/javascript/constants'
import { eventBus } from './../assets/javascript/event-bus'
import { HTML_IDE_UTIL } from '../assets/javascript/html-ide-util'
import TreeItemRO from './Utils/TreeItemRO'

export default {
  name: 'myProjects',
  mixins: [formMixin],
  props: ['language', 'languageName', 'projectId', 'aceLanguageCode', 'isMultiFileLang'],
  components: { TreeItemRO, Spinner, DoodleMessage, PageTitle, SideBar },
  data: function () {
    return {
      projects: [],
      isMounted: false,
      project: null,
      showDeleteConfirm: false,
      showFileDeleted: false,
      copiedToClip: false,
      showCopiedMessageText: ''
    }
  },
  mounted () {
    this.executeAPIWitoutValiation({
      url: '/api/doodle/myfiles',
      method: 'post',
      data: { lang: this.language, isMultiFile: this.isMultiFileLang },
      successAction: (data) => {
        this.projects = data.files
        this.isMounted = true
      },
      failureAction: null,
      markCompleted: false,
      form: this.doodleForm,
      jdaCategory: window.jda.CATEGORY.IDE,
      jdaEvent: 'list-my-projects',
      jdaLabel: this.language
    })

    if (!this.isMultiFileLang) {
      this.initFileEditor()
    }
  },
  methods: {
    openProject () {
      eventBus.$emit((this.language === 'html') ? 'HtmlIdeOpenProject' : 'IdeOpenProject', { project: this.project })
      this.$refs.sideBar.emitToggle()
    },
    loadFile: function (project) {
      if (this.project && project.id === this.project.id) {
        return
      }
      this.project = null

      if (!this.isMultiFileLang) {
        window.ace.edit(IDE_CONST.FILE_EDITOR).getSession().setValue('')
      }

      this.executeAPIWitoutValiation({
        url: '/api/doodle/file',
        data: {
          id: project.id, lang: this.language, isMultiFile: this.isMultiFileLang
        },
        method: 'post',
        successAction: (data) => {
          if (data.project.libraries) {
            data.project.libraries = this.$_.compact(data.project.libraries.split(' '))
          }

          if (this.isMultiFileLang) {
            data.project.script = JSON.parse(data.project.script)
          }

          this.project = data.project

          let script
          if (this.language === 'html') {
            script = HTML_IDE_UTIL.getHtmlCode(this.project.docType, this.project.htmlHead, this.project.htmlBody, this.project.jsCode, this.project.cssCode)
          } else if (!this.isMultiFileLang) {
            script = this.project.script
          }

          if (!this.isMultiFileLang) {
            window.ace.edit(IDE_CONST.FILE_EDITOR).getSession().setValue(script)
          }
        },
        failureAction: null,
        markCompleted: false,
        form: this.doodleForm,
        showFileDeleted: true,
        jdaCategory: window.jda.CATEGORY.IDE,
        jdaEvent: 'open-project',
        jdaLabel: this.language
      })
    },
    initFileEditor () {
      this.fileEditor = ACE_UTIL.initEditor(IDE_CONST.FILE_EDITOR, this.aceLanguageCode)
      this.fileEditor.renderer.setShowGutter(true)
      this.fileEditor.setReadOnly(true)

      if (this.language === 'whitespace') {
        this.codeEditor.setShowInvisibles(true)
      }
    },
    deleteProject () {
      if (!this.project) {
        return
      }

      this.executeAPIWitoutValiation({
        url: '/api/doodle/deletefile',
        data: { id: this.project.id, lang: this.language, isMultiFile: this.isMultiFileLang },
        method: 'post',
        successAction: (data) => {
          if (this.projectId === this.project.id) {
            eventBus.$emit((this.language === 'html') ? 'HtmlIdeClearProject' : 'IdeClearProject')
          }
          this.$_.remove(this.projects, { id: this.project.id })
          if (!this.isMultiFileLang) {
            window.ace.edit(IDE_CONST.FILE_EDITOR).getSession().setValue('')
          }

          this.showFileDeleted = true
          this.$_.delay(() => { this.showFileDeleted = false }, 4000, '')
          this.showDeleteConfirm = false
          this.project = null
        },
        failureAction: null,
        markCompleted: false,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.IDE,
        jdaEvent: 'delete-project',
        jdaLabel: this.language
      })
    },
    copyToClipboard: function (txt) {
      window.navigator.clipboard.writeText(txt).then((e) => {
        this.copiedToClip = true
        this.showCopiedMessageText = 'Copied to clipboard!'
      }, (e) => {
        this.copiedToClip = true
        this.showCopiedMessageText = 'Copy to clipboard failed!'
      })

      this.$_.delay(() => { this.copiedToClip = false }, 3000)
    },
    isSelected (project) {
      if (this.project) {
        return project.id === this.project.id
      }

      return false
    }
  },
  computed: {
    title () {
      return 'My ' + this.languageName + ' Projects'
    },
    showMainSection () {
      return this.isMounted
    },
    showShared () {
      return this.project && this.project.shared === true
    }
  }
}
</script>

<style scoped lang="scss">
  @import "./../assets/style/screen-size.scss";
  @import "./../assets/style/ide.scss";

  .doodle-display-box {
    margin: 0.25em;
    padding: 1.5em;
  }

  .file-div {
  }

  .file-list {
    max-height: 300px;
    background: white;
    overflow: scroll;
  }

  .file-list::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  .file-list::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

  .file-list-item {
    font-size: 0.9em;
    padding: 0.5em;
    font-weight: 600;
    border-bottom: 1px solid #f3f0f0;

    @media screen and (max-width: $tablet) {
      font-size: 0.8em;
    }
  }

  .file-list-item:hover {
    cursor: pointer;
    background: lightgrey;
  }

  #myFilesEditor {
    min-height: 300px;
    margin: 0;
  }

  .code-editor-div {
    padding-top: 0;
    padding-right: 0;
  }

  .share-div {
    font-size: 0.9em;
    font-weight: 600;
    padding: 0.6em;
  }

  .share-box {
    display: inline;
    padding: 0.5em;
  }

  .ide-menu-box > .box {
    margin: 1em;
    background: hsl(0, 0%, 96%);
  }

  .confirm-text {
    font-size: 0.9em;
    margin-bottom: 1em;
  }

  .file-list-item.is-active {
    background: lightgray;
  }
</style>
